import React from 'react';
import styled from 'styled-components';

import InfiniteSentenceBanner from 'components/atoms/InfiniteSentenceBanner';
import SectionA from 'components/organisms/PhilingMainSectionsForPt/SectionA';
import SectionB from 'components/organisms/PhilingMainSectionsForPt/SectionB';
import SectionC from 'components/organisms/PhilingMainSectionsForPt/SectionC';
import SectionD from 'components/organisms/PhilingMainSectionsForPt/SectionD';
import SectionE from 'components/organisms/PhilingMainSectionsForPt/SectionE';
import SectionI from 'components/organisms/PhilingMainSectionsForPt/SectionI';
import { questions } from 'utils/constants';

const MAIN_IMAGES = [
  // {
  //   src: 'https://images.odoctor.co.kr/philing/card/pt-yt-thumb-1.jpg',
  //   pre: 'https://images.odoctor.co.kr/philing/card/pt-yt-thumb-1.jpg',
  //   title: '오늘 시작해볼까요?',
  //   desc: '의사에게 직접 진료받고 믿을 수 있는 처방약을\n집까지 무료로 받아보세요.',
  // },
  {
    src: 'https://images.odoctor.co.kr/philing/card/pt-yt-thumb-1.jpg',
    pre: 'https://images.odoctor.co.kr/philing/card/pt-yt-thumb-1.jpg',
    title: '',
    desc: '',
    youtubeId: 'J6HxSj32YZY',
  },
  {
    src: 'https://images.odoctor.co.kr/philing/card/pt-yt-thumb-2.jpg',
    pre: 'https://images.odoctor.co.kr/philing/card/pt-yt-thumb-2.jpg',
    title: '',
    desc: '',
    youtubeId: 'WzwFieMUhyk',
  },
  {
    src: 'https://images.odoctor.co.kr/philing/card/pt-yt-thumb-3.jpg',
    pre: 'https://images.odoctor.co.kr/philing/card/pt-yt-thumb-3.jpg',
    title: '',
    desc: '',
    youtubeId: 'ftUOfCX5YKU',
  },
  {
    src: 'https://images.odoctor.co.kr/philing/card/pt-yt-thumb-4.jpg',
    pre: 'https://images.odoctor.co.kr/philing/card/pt-yt-thumb-4.jpg',
    title: '',
    desc: '',
    youtubeId: 'iXbzaRqF4Ts',
  },
];

export default function PhilingMainTemplate() {
  return (
    <Container>
      <SectionAWrapper>
        <SectionA />
      </SectionAWrapper>
      <InfiniteSentenceBanner
        sentence={
          <BannerText>
            Regain <BannerBold>Your Youth</BannerBold>
          </BannerText>
        }
      />
      <SectionB />

      <SectionD />
      <InfiniteSentenceBanner
        sentence={
          <BannerText>
            <BannerBold>Delight</BannerBold> for <BannerBold>Myself</BannerBold>
          </BannerText>
        }
      />
      <SectionC images={MAIN_IMAGES} />
      {/* FAQ */}
      <SectionE questions={questions} />

      <SectionI />
    </Container>
  );
}

const Container = styled.div``;

const BannerText = styled.span`
  font-family: 'Heebo';
  font-size: 1.2rem;
`;

const BannerBold = styled.span`
  font-size: 1.2rem;
  font-family: 'Taviraj';
  font-style: italic;
  font-weight: bold;
`;

const SectionAWrapper = styled.div`
  background-color: #eeeeee;
  margin: 0 -1.4rem;
  padding: 0 1.4rem;
  padding-bottom: 4rem;
`;
