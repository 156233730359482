import React, { useState } from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import ProgressiveImage from 'react-progressive-graceful-image';
import YouTube from 'react-youtube';

const opts = {
  height: '100%',
  width: '100%',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};

const STEPS = [
  {
    src: 'https://images.odoctor.co.kr/philing/card/pt-s_01.jpg',
    title: '나이들수록 심해지는\n불독살 해결했어요',
    desc: '',
  },
  {
    src: 'https://images.odoctor.co.kr/philing/card/pt-s_02.jpg',
    title: '안면거상술로\n10년전 얼굴 되찾았어요',
    desc: '',
  },
  {
    src: 'https://images.odoctor.co.kr/philing/card/pt-s_03.jpg',
    title: '임플란트 없이\n밝고 가지런한 치아로?',
    desc: '',
  },
  {
    src: 'https://images.odoctor.co.kr/philing/card/pt-s_04.jpg',
    title: '임플란트 없이\n밝고 가지런한 치아로?',
    desc: '',
  },
];

const Shorts = [
  {
    youtubeId: '3BDXzRJqeh8',
    src: 'https://images.odoctor.co.kr/philing/card/pt-s_05.jpg',
    pre: 'https://images.odoctor.co.kr/philing/card/pt-s_05.jpg',
    title: '오늘 시작해볼까요?',
    desc: '의사에게 직접 진료받고 믿을 수 있는 처방약을\n집까지 무료로 받아보세요.',
  },
  {
    youtubeId: 'GeX6j8Xb_Gw',
    src: 'https://images.odoctor.co.kr/philing/card/pt-s_06.jpg',
    pre: 'https://images.odoctor.co.kr/philing/card/pt-s_06.jpg',
    title: '오늘 시작해볼까요?',
    desc: '의사에게 직접 진료받고 믿을 수 있는 처방약을\n집까지 무료로 받아보세요.',
  },
  {
    youtubeId: '-uvLJTyzX4Y',
    src: 'https://images.odoctor.co.kr/philing/card/pt-s_07.jpg',
    pre: 'https://images.odoctor.co.kr/philing/card/pt-s_07.jpg',
    title: '오늘 시작해볼까요?',
    desc: '의사에게 직접 진료받고 믿을 수 있는 처방약을\n집까지 무료로 받아보세요.',
  },
  {
    youtubeId: 'Qy_Cjlv37NI',
    src: 'https://images.odoctor.co.kr/philing/card/pt-s_08.jpg',
    pre: 'https://images.odoctor.co.kr/philing/card/pt-s_08.jpg',
    title: '오늘 시작해볼까요?',
    desc: '의사에게 직접 진료받고 믿을 수 있는 처방약을\n집까지 무료로 받아보세요.',
  },
];

const YPlayer = ({ children, id }) => {
  const [p, setP] = useState(null);
  const [s, setS] = useState(true);
  const onRd = (e) => {
    setP(e);
  };

  const onPs = (e) => {
    setS(true);
  };

  return (
    <>
      <YouTube videoId={id} opts={opts} onReady={onRd} onPause={onPs} />

      <div
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 80,
          display: s ? 'block' : 'none',
        }}
        onClick={() => {
          p.target.playVideo();
          setS(false);
        }}
      >
        {children}
      </div>
    </>
  );
};

export default function SectionB() {
  return (
    <Container>
      <InView triggerOnce threshold={0.5}>
        {({ inView, ref }) => (
          <TextWrapper ref={ref} inView={inView}>
            <Text>
              젊어지는 <Bold>꿀팁 숏츠</Bold>
            </Text>
          </TextWrapper>
        )}
      </InView>
      {/* <ProgressiveImage
        src="https://images.odoctor.co.kr/philing/card/image_self_desc.webp"
        placeholder="https://images.odoctor.co.kr/philing/card/image_self_desc.webp"
      >
        {(src, loading) => (
          <ImageWrap>
            <ImageGradientLarge
              src="https://images.odoctor.co.kr/philing/card/gradient_effect_large.webp"
              alt="gradient"
            />
            <Image style={{ opacity: loading ? 0.5 : 1 }} src={src} alt="an image" />
            <ImageGradientSmall
              src="https://images.odoctor.co.kr/philing/card/gradient_effect_large.webp"
              alt="gradient"
            />
          </ImageWrap>
        )}
      </ProgressiveImage> */}
      <div>
        <YoutubeWrap>
          {Shorts.map(({ src, title, desc, pre, youtubeId }, idx) => (
            <div className="yt-box" key={idx}>
              <Wrap>
                <ProgressiveImage src={src} placeholder={pre}>
                  {(src, loading) => {
                    return (
                      <Ytwrap>
                        <YPlayer id={youtubeId}>
                          <FullSizeImage
                            style={{ opacity: loading ? 0.5 : 1 }}
                            src={src}
                            alt={title}
                          />
                        </YPlayer>
                      </Ytwrap>
                    );
                  }}
                </ProgressiveImage>
              </Wrap>
            </div>
          ))}

          {/* <div className="yt-box">
            <YouTube videoId="pzky_1Vn5P8" opts={opts} />
          </div> */}
        </YoutubeWrap>
      </div>

      <Content style={{ display: 'none' }}>
        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <SubTitleWrapper ref={ref} inView={inView}>
              {/* <PenImage inView={inView} src={ImgTextHighlight} /> */}
              <SubTitle inView={inView}>
                <Mark>더욱 더 간편하게 나다워질 수 있도록</Mark>
              </SubTitle>
            </SubTitleWrapper>
          )}
        </InView>
        <InView triggerOnce threshold={0.5}>
          {({ inView, ref }) => (
            <DescriptionWrap ref={ref} inView={inView}>
              <Description>의료 전문가의 진료를 언제 어디서나,</Description>
              <Description>간편한 약 배송까지</Description>
              <Description>이제 스스로를 더 쉽게 가꿀 수 있어요.</Description>
            </DescriptionWrap>
          )}
        </InView>
      </Content>
    </Container>
  );
}

const YoutubeWrap = styled.div`
  display: grid;
  grid-gap: 1rem;

  grid-template-columns: 1fr 1fr;
  .yt-box {
    background-color: #494912;
    border-radius: 1rem;
    overflow: hidden;
    padding-top: 160%;
    position: relative;
  }
`;

const Container = styled.div`
  padding: 1.4rem;
  position: relative;
  margin: 0 -1.4rem;
  margin-top: 5rem;
`;

const ImageWrap = styled.div`
  position: relative;
  overflow: hidden;
`;

const ImageGradientLarge = styled.img`
  position: absolute;
  width: 36rem;
  transform: translate(-35%, -35%);
`;

const ImageGradientSmall = styled.img`
  position: absolute;
  width: 20rem;
  right: 0;
  bottom: 0;
  transform: translate(45%, 15%);
`;

const Image = styled.img`
  width: 100%;
  aspect-ratio: 36 / 25;
`;

const TextWrapper = styled.div`
  transform: translateY(${({ inView }) => (inView ? '0' : '-3rem')});
  opacity: ${({ inView }) => (inView ? '1' : '0')};
  transition: all 1.5s ease 0.3s;
`;

const Text = styled.div`
  font-family: 'Heebo';
  font-size: 3rem;
`;

const Bold = styled.span`
  font-family: 'Taviraj';
  font-style: italic;
  font-size: 3rem;
  font-weight: bold;
  line-height: 50%;
`;

const Content = styled.div`
  background-color: #eeeeee;
  padding: 5rem 2rem 7rem 2rem;
`;

const SubTitleWrapper = styled.div`
  position: relative;
  transform: translateY(${({ inView }) => (inView ? '0' : '-3rem')});
  opacity: ${({ inView }) => (inView ? '1' : '0')};
  transition: all 1.5s ease;
`;

const PenImage = styled.img`
  width: 25rem;
  position: absolute;
  left: -1rem;
  top: 0.5rem;
`;

const SubTitle = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 2rem;
  position: relative;
  margin-left: -0.6rem;
`;

const Mark = styled.mark`
  font-size: 1.4rem;
  font-weight: bold;
`;

const DescriptionWrap = styled.div`
  transform: translateY(${({ inView }) => (inView ? '0' : '-3rem')});
  opacity: ${({ inView }) => (inView ? '1' : '0')};
  transition: all 1.5s ease;
`;

const Description = styled.div`
  font-size: 1.4rem;
`;

const Ytwrap = styled.div`
  width: 100%;
  height: 100%;
  & > div {
    height: 100%;
  }
  -webkit-user-drag: none;
  // aspect-ratio: 9 / 16;
  position: relative;
  overflow: hidden;
`;

const Wrap = styled.div`
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

const FullSizeImage = styled.img`
  width: 100%;
  -webkit-user-drag: none;
  aspect-ratio: 83 / 143;
`;
